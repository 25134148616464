<template>
  <div class="generation_view">
    <div class="form_view">
      <img class="integral_icon" src="https://freesaasofficedata.oss.aliyuncs.com/00b21aa51c3f4e7eae134aa6766a2d41.jpg" />
    </div>
    <div class="video_view">
      <video controls autoplay height="290px" width="100%" class="video" webkit-playsinline playsinline 
      src="https://freesaasofficedata.oss.aliyuncs.com/b01071eddca540faa7529a8c3b84cee5.mp4" ></video>
    </div>
    <div class="form_view">
      <img class="integral_icon" src="https://freesaasofficedata.oss.aliyuncs.com/2753f7eb4b6b4921b08000886b8148b1.jpg " />
    </div>
  </div>

</template>
<script>
export default {
  name: "GenerationQrcode",
  components: {
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.generation_view {
  .video_view {
    background-color: #ededed;
    position: relative;
    box-sizing: border-box;
    height: 290px;
  }
  .form_view {
    img {
      width: 100%;
    }
  }
}
img, video {
  display: block;
  margin: 0;
  padding: 0;
}
</style>